<script>
import BaseInformationForm from './SystemBaseForm.vue'
import { mapMutations } from 'vuex'
import solar from '@/mixins/solar'
export default {
  extends: BaseInformationForm,
  mixins: [solar],
  methods: {
    ...mapMutations({
      addSystem: 'solar/addSystem'
    }),
    submit() {
      this.addSystem(this.copyObject(this.formData))
      this.saveSolarData(this.$route.params.id)
      this.resetForm()

      this.updateMetrics({ feature: 'solar', action: 'create' })
    }
  }
}
</script>
