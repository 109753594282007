<template>
  <v-main>
    <v-container>
      <peeps-alert-animation
        classes="float-bottom-left"
        :alertAttrs="peepsAlert.alertAttrs"
        :color="peepsAlert.alertAttrs.color"
        peepsRight
        :timeout="peepsAlert.timeout"
        ref="solarDatabaseAnimation"
        :message="peepsAlert.message"
        :value="peepsAlert.show"
      ></peeps-alert-animation>
      <v-dialog v-model="dialogs.ecData" max-width="500" persistent>
        <v-card class="mx-auto">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn @click="dialogs.ecData = false" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text v-if="ecData">
            <p class="title">
              We have found your
              {{
                ecData.count >= 2
                  ? `${ecData.count} solar systems`
                  : `solar system`
              }}
              on the public record database. Would you like to import the data?
            </p>
            <div class="text-center">
              <v-btn @click="acceptECData()" color="success" large
                >Yes <v-icon>mdi-check-all</v-icon></v-btn
              >
              <v-btn
                text
                color="secondary"
                large
                @click="dialogs.ecData = false"
                >No</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <feature-breadcrumb :feature="fBreadcrumb">
        <template #action>
          <ParcelGalleryButton
            v-model="gallery"
            class="ml-1"
            icon
            :count="galleryCount"
          />
        </template>
      </feature-breadcrumb>
      <v-row>
        <v-col cols="12">
          <FeatureGallery
            feature="Solar"
            v-model="gallery"
            :count.sync="galleryCount"
          />
          <v-card max-width="1300" class="mx-auto">
            <v-card-title
              class="primary--text cursor-pointer"
              @click="showForm = !showForm"
              >Solar System Information
              <v-icon color="primary">{{ getToggleIcon }}</v-icon>
            </v-card-title>
            <v-fade-transition>
              <v-container class="mt-n3" v-show="showForm">
                <div class="ml-5 mr-5 mb-2">
                  <SystemAddForm></SystemAddForm>
                </div>
              </v-container>
            </v-fade-transition>
          </v-card>
        </v-col>
      </v-row>

      <div class="mt-3">
        <v-card
          ><v-container> <SystemList></SystemList> </v-container
        ></v-card>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import SystemAddForm from '@/components/Solar/SystemAddForm'
import SystemList from '@/components/Solar/SystemList'

import { mapGetters, mapActions } from 'vuex'
import { eventRef } from '../../../firebase/db'
import parcel from '@/mixins/parcel'
import solar from '@/mixins/solar'
import user from '@/mixins/user'
import Moment from 'moment'
import config from '../../../config'
const api = config.api()
export default {
  mixins: [parcel, solar, user],
  components: {
    SystemAddForm,
    SystemList
  },
  data: () => ({
    gallery: false,
    galleryCount: 0,
    fBreadcrumb: {
      text: 'Solar',
      to: '#',
      disabled: true
    },
    dialogs: {
      ecData: false
    },
    showForm: true,
    ecData: null,
    peepsAlert: {
      show: false,
      message: 'Looking in solar database for this parcel',
      timeout: 4000,
      alertAttrs: { color: 'success', dark: true, outlined: false }
    }
  }),
  computed: {
    getToggleIcon() {
      if (this.showForm) {
        return 'mdi-chevron-up'
      } else {
        return 'mdi-chevron-down'
      }
    },
    url() {
      return this.$route.query.info
    },
    infoDialog() {
      return this.dialogs.information
    },
    ...mapGetters({
      solarData: 'solar/data'
    })
  },
  async mounted() {
    await this.getSolarData(this.$route.params.id)
    if (!this.solarData.solar_systems.length) {
      let previousChecks = await this.getDatabaseChecks()
      if (!previousChecks || this.$store.getters['solar/systems'].length) {
        this.checkECDatabase()
      }
    }
  },
  destroyed() {
    this.clearData()
  },
  methods: {
    ...mapActions({
      clearData: 'solar/clearData'
    }),
    async onDatabaseCheck() {
      this.logEvent('solar_database_check', { parcel: this.$route.params.id })
        .then(() => {})
        .catch(err => {
          console.log(err)
        })
    },
    async getDatabaseChecks() {
      let user = await this.currentUser()
      if (!user) {
        return
      }
      let res = await eventRef
        .where('user', '==', user.uid)
        .where('parcel', '==', this.$route.params.id)
        .where('type', '==', 'solar_database_check')
        .get()
      let found = false
      res.forEach(e => {
        let data = e.data()
        if (data.timestamp) {
          let ts = this.parseTimestamp(data.timestamp)
          let diff = Moment(new Date()).diff(ts, 'minutes')
          if (diff < 5) {
            found = true
          }
        }
      })
      return found
    },
    solarDataNotFound() {
      setTimeout(() => {
        this.peepsAlert.animation = false
        this.peepsAlert.alertAttrs.color = 'peeps-light-yellow'
        this.peepsAlert.alertAttrs.dark = false
        this.peepsAlert.message = 'Could not find solar data for this parcel'
        this.$refs.solarDatabaseAnimation.onClick()
        setTimeout(() => {
          this.$refs.solarDatabaseAnimation.animate()
        }, 1000)
      }, 3000)
    },
    async checkECDatabase() {
      this.$refs.solarDatabaseAnimation.animate()
      this.ecData = await this.$axios.get(
        `${api.cloudFunctionAPI}/check/solar?type=ec&id=${this.$route.params.id}`
      )
      this.onDatabaseCheck()
      if (!this.ecData) {
        this.solarDataNotFound()
        return
      }

      this.ecData = this.ecData.data
      if (this.ecData.exists) {
        this.dialogs.ecData = true
      } else {
        this.solarDataNotFound()
      }
    },
    async acceptECData() {
      await this.$axios
        .get(
          `${api.cloudFunctionAPI}/clone/ec-data?id=${this.$route.params.id}&ec_id=${this.ecData.id}`
        )
        .then(res => {
          if (res.status == 200) {
            this.dialogs.ecData = false
            this.getSolarData(this.$route.params.id)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style></style>
